<template>
  <div class="container">
    <Navbar pageName="개인정보 처리방침" />
    <div class="legalContent">
      <h3>그린데이터랩 주식회사 개인정보처리방침</h3>

      <ul>
        <li>
          그린데이터랩 주식회사 (이하 "그린데이터랩"이라 함)이 취급하는 모든
          개인정보는 관련법령에 근거하거나 정보주체의 동의에 의하여 수집·보유 및
          처리되고 있습니다.
        </li>

        <li>
          그린데이터랩은 개인정보 보호법 제30조에 따라 정보주체의 개인정보를
          보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기
          위하여 다음과 같이 개인정보 처리지침을 수립·공개합니다.
        </li>
      </ul>
      <b> 제1조(개인정보의 처리목적)</b>
      <ul>
        <li>
          ①그린데이터랩은 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고
          있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용
          목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를
          받는 등 필요한 조치를 이행할 예정입니다.
        </li>

        <li>서비스 제공</li>
        <li>
          - 회원제 서비스 (병해충 진단하기, 커뮤니티 등) 제공, 회원별 축적된
          데이터(병해충 진단이력, 농가정보) 리포트, 본인인증 등 서비스 제공 관련
          목적을 위해 개인정보를 처리합니다.
        </li>
        <li>민원사무 처리</li>
        <li>
          - 개인정보 열람·정정·삭제·처리정지 요구 각종고지·고충처리·분쟁조정
          목적을 위해 개인정보 처리합니다.
        </li>

        <li>
          ②그린데이터랩은 처리하고 있는 개인정보는 목적 이외의 용도로 이용되지
          않으며, 이용 목적이 변경되는 경우에는 개인정보보호법 제18조에 따라
          별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
        </li>
      </ul>
      <b> 제2조(개인정보의 처리 및 보유기간)</b>
      <ul>
        <li></li>
        <li>
          ①그린데이터랩은 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터
          개인정보를 수집 시에 동의 받은 개인정보 보유·이용기간 내에서
          개인정보를 처리·보유합니다.
        </li>
        <li>
          ②그린데이터랩은 개인정보보호법 제32조에 따라 등록 공개하여야 하는
          개인정보파일의 운영목적 근거 개인정보 항목 및 기간은 다음과 같습니다.
        </li>
        <li>-전화번호</li>
        <li>-보유기간 : 회원 탈퇴 시 까지</li>
      </ul>
      <b> 제3조(개인정보의 제3자 제공)</b>
      <ul>
        <li>
          ①그린데이터랩은 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서
          명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등
          개인정보 보호법 제17조 및 제18조에 의거하여 다음 각 호에 해당하는
          경우에만 개인정보를 제3자에게 제공합니다.
        </li>
        <li>
          정보주체의 별도의 동의를 받은 경우, 법률에 특별한 규정이 있거나 법령상
          의무를 준수하기 위하여 불가피한 경우
        </li>
        <li>
          공공기관이 법령 등에서 정하는 소관 업무를 수행하기 위하여 불가피한
          경우
        </li>
        <li>
          정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나
          주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는
          제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는
          경우
        </li>
        <li>
          ②그린데이터랩은 다음과 같이 개인정보를 제3자에게 제공할 수도 있습니다.
        </li>
      </ul>
      <b> 제4조(개인정보처리의 위탁)</b>
      <ul>
        <li></li>
        <li>
          ①이용자의 동의 없이 해당 개인정보의 처리를 타인에게 위탁하지 않습니다.
        </li>

        <li>
          ②그린데이터랩은 위탁계약 체결 시 개인정보 보호법 제25조에 따라
          위탁업무 수행목적 외 개인정보 처리금지, 기술적·관리적 보호조치, 재위탁
          제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서
          등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고
          있습니다.
        </li>
      </ul>
      <b> 제5조(정보주체와 법정대리인의 권리· 의무 및 행사방법)</b>
      <ul>
        <li>
          ①정보주체는 그린데이터랩에 대해 언제든지 개인정보
          열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
        </li>
        <li>
          ②제1항에 따른 권리 행사는 그린데이터랩에 대해 개인정보 보호법 시행령
          제41조제1항에 따라 서면, 전자우편 등을 통하여 하실 수 있으며,
          그린데이터랩은 이에 대해 지체없이 조치하겠습니다.
        </li>
        <li>
          ③제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등
          대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 처리 방법에 관한
          고시 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
        </li>
        <li>
          ④개인정보 열람 및 처리정지 요구는개인정보보호법 제35조 제4항, 제37조
          제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
        </li>
        <li>
          ⑤개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
          대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
        </li>
        <li>
          ⑥그린데이터랩은 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구,
          처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한
          대리인인지를 확인합니다.
        </li>
      </ul>
      <b> 제6조(개인정보의 파기)</b>
      <ul>
        <li>그린데이터랩은 다음의 개인정보 항목을 처리하고 있습니다.</li>

        <li>
          ①그린데이터랩은 은 원칙적으로 개인정보 처리목적이 달성된 경우에는
          지체없이 해당 개인정보를 파기합니다. 다만, 다른 법률에 따라
          보존하여야하는 경우에는 그러하지 않습니다. 파기의 절차, 기한 및 방법은
          다음과 같습니다.
        </li>

        <li>파기절차</li>
        <li>
          그린데이터랩은 파기하여야 하는 개인정보(또는 개인정보파일)에 대해
          개인정보 파기계획을 수립하여 파기합니다. 그린데이터랩은 파기 사유가
          발생한 개인정보(또는 개인정보파일)을 선정하고, 그린데이터랩은 개인정보
          보호책임자의 승인을 받아 개인정보(또는 개인정보파일)을 파기합니다.
        </li>

        <li>파기방법</li>
        <li>
          그린데이터랩은 전자적 파일 형태로 기록, 저장된 개인정보는 기록을
          재생할 수 없도록 로우레밸포멧(Low Level Format) 등의 방법을 이용하여
          파기하며, 종이 문서에 기록, 저장된 개인정보는 분쇄기로 분쇄하거나
          소각하여 파기합니다.
        </li>
      </ul>

      <b> 제7조(개인정보의 안전성 확보조치)</b>
      <ul>
        <li>
          ①그린데이터랩은 개인정보의 안전성 확보를 위해 다음과 같이 관리적,
          기술적, 물리적 조치를 취하고 있습니다.
        </li>
        <li>내부관리계획의 수립 및 시행</li>
        <li>
          그린데이터랩의 내부관리계획 수립 및 시행은 행정안전부의 내부관리
          지침을 준수하여 시행합니다.
        </li>
        <li>개인정보 취급 담당자의 최소화 및 교육</li>
        <li>
          개인정보를 취급하는 담당자를 지정하고 최소화하여 개인정보를 관리하는
          대책을 시행하고 있습니다.
        </li>
        <li>개인정보에 대한 접근 제한</li>
        <li>
          개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경,
          말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고
          있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고
          있습니다.
        </li>
        <li>접속기록의 보관 및 위변조 방지</li>
        <li>
          개인정보처리시스템에 접속한 기록(웹 로그, 요약정보 등)을 최소 6개월
          이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지
          않도록 보안기능을 사용하고 있습니다.
        </li>
        <li>개인정보의 암호화</li>
        <li>이용자의 개인정보는 암호화되어 저장 및 관리되고 있습니다.</li>
        <li>해킹 등에 대비한 기술적 대책</li>
        <li>
          그린데이터랩은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및
          훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며
          외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적·물리적으로
          감시 및 차단하고 있습니다. 또한 네트워크 트래픽의 통제(Monitoring)는
          물론 불법적으로 정보를 변경하는 등의 시도를 탐지하고 있습니다.
        </li>
        <li>비인가자에 대한 출입 통제</li>
        <li>
          개인정보를 보관하고 있는 개인정보시스템의 물리적 보관 장소를 별도로
          두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.
        </li>
      </ul>
      <b> 제8조(개인정보 보호책임자)</b>
      <ul>
        <li></li>
        <li>
          ①그린데이터랩은 개인정보 처리에 관한 업무를 총괄해서 책임지고,
          개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여
          아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
        </li>
        <li>개인정보 책임자 목록</li>
        <li>구분 부서명 성명 연락처</li>
        <li>책임자 그린데이터랩 주식회사</li>
        <li>02-2039-8887</li>
        <li>Gdatalab.info@gmail.com</li>
        <li>개인정보보호 담당자 그리데이터랩 주식회사 02-2039-8887</li>
        <li>Gdatalab.info@gmail.com</li>
      </ul>
      <b> 제9조(개인정보 열람청구)</b>
      <ul>
        <li></li>
        <li>
          ①정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를
          아래의 부서에 할 수 있습니다. 그린데이터랩은 정보주체의 개인정보
          열람청구가 신속하게 처리되도록 노력하겠습니다.
        </li>
        <li>- 그린데이터랩 IT 센터 : 2039-8887</li>
        <li>
          ②정보주체께서는 제1항의 열람청구 접수, 처리부서 이외에,
          개인정보보호위원회의 ‘개인정보보호 포털(www.privacy.go.kr)’을
          통하여서도 개인정보 열람청구를 하실 수 있습니다.
        </li>
        <li>▶개인정보파일 등록 현황 조회방법</li>
        <li>
          개인정보보호위원회 개인정보보호 포털 → 개인정보 민원 → 개인정보 열람
          등 요구 → 개인정보파일 목록 검색 → 기관명에 ‘그린데이터랩’ 입력
        </li>
      </ul>
      <b> 제10조(권익침해 구제방법)</b>
      <ul>
        <li>
          정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을
          문의하실 수 있습니다.
        </li>
        <li>
          &#60;아래는 그린데이터랩과는 별개의 기관으로서, 그린데이터랩의
          자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다
          자세한 도움이 필요하시면 문의하여 주시기 바랍니다. &#62;
        </li>
        <li>▶개인정보 침해신고센터 (한국인터넷진흥원 운영)</li>
        <li>- 소관업무 : 개인정보 침해사실 신고, 상담 신청</li>
        <li>- 홈페이지 : privacy.kisa.or.kr</li>
        <li>- 전화 : (국번없이) 118</li>
        <li>
          - 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층
          개인정보침해신고센터
        </li>
        <li>▶개인정보 분쟁조정위원회</li>
        <li>- 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)</li>
        <li>- 홈페이지 : www.kopico.go.kr</li>
        <li>- 전화 : (국번없이) 1833-6972</li>
        <li>- 주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층</li>
        <li>▶대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)</li>
        <li>▶경찰청 사이버안전국 : 182 (http://cyberbureau.police.go.kr)</li>
      </ul>
      <b> 제11조(개인정보 처리방침 변경)</b>
      이 개인정보 처리방침은 2022. 11.07 부터 적용됩니다.
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
export default {
  components: { Navbar },

  data() {
    return {};
  },
  created() {
    this.$store.dispatch("SET_MENU_VISIBLE", false);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", false);
  },
  methods: {},
};
</script>
